/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { CardImg, CardImgOverlay, Card, CardTitle } from 'reactstrap';
import HarvestLogo from '../resources/harvest_logomark_white.svg';
import { styles } from './WalmartMarketplaceApiAuthStyles';
import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  Typography,
  Alert,
  TextField,
  Snackbar,
  Stack,
} from '@mui/material';
import { Facebook } from '@mui/icons-material';
import { Instagram } from '@mui/icons-material';
import { LinkedIn } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import { WALMART_MARKETPLACE_API_REDIRECT_URI } from '../api-config';
import { GenerateAndSaveWalmartMarketplaceRefreshToken } from '../Utilities/ForemanApiClient';
import Loader from '../V2/Components/Loader/Loader';

export default function WalmartMarketplaceApiCallback(props) {
  const [refreshToken, setRefreshToken] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [stateCookies] = useCookies(['foremanState']);
  const [credentialCookies] = useCookies(['credentialId']);

  const verifiedForemanState = stateCookies.foremanState;
  const credentialId = credentialCookies.credentialId;

  const requestParameters = new URLSearchParams(window.location.search);
  const unverifiedForemanState = requestParameters.get('state');
  const oauthCode = requestParameters.get('code');
  const sellerId = requestParameters.get('sellerId');
  const partnerId = requestParameters.get('partnerId');
  const clientType = requestParameters.get('partnerType');

  const genericErrorMessage = `ERROR: Something went wrong when trying to grant Harvest Group LLC access to your ${
    clientType === 'supplier' ? 'Supplier One data' : 'Walmart Marketplace data'
  } as a service provider.`;

  const successMessage = `Harvest Group LLC is now authorized to access your ${
    clientType === 'supplier' ? 'Supplier One data' : 'Walmart Marketplace data'
  }. Please keep the following code for your records:`;

  const isValidForemanState = () => {
    return unverifiedForemanState === verifiedForemanState;
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(refreshToken).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleRefreshTokenGeneration = async () => {
    setIsLoading(true);

    const response = await GenerateAndSaveWalmartMarketplaceRefreshToken(
      credentialId,
      oauthCode,
      WALMART_MARKETPLACE_API_REDIRECT_URI,
      sellerId ? sellerId : partnerId,
      clientType
    );

    setRefreshToken(response);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isValidForemanState()) {
      handleRefreshTokenGeneration();
    }
  }, []);

  useEffect(() => {
    if (refreshToken) {
      setIsLoading(false);
    }
  }, [refreshToken]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar position="static" sx={styles.NavBarStyle}>
          <img
            alt="system"
            src="/whitelogo.svg"
            style={{ width: '100px', height: '50px', marginLeft: '20px', marginTop: '12px' }}
          />
        </AppBar>

        <div className="content compact-centered">
          <Container component="main" maxWidth="lg" sx={styles.UpdatedContainer}>
            <Container component="main" maxWidth="lg" sx={styles.Container}>
              <CssBaseline />

              <Box sx={styles.BoxStyles}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <div>
                    {refreshToken ? (
                      <>
                        <Grid container sx={styles.MainGrid}>
                          <Grid item xs={10}>
                            <Typography component="h1" variant="h5">
                              Authorization Successful!
                            </Typography>
                          </Grid>
                          <Grid item xs={2} align="right">
                            <img alt="system" src="/systemJobLightMode.svg" style={{ width: '60px', height: '80px' }} />
                          </Grid>
                          <Alert icon={false} severity="info" sx={styles.AlertStyle}>
                            {successMessage}
                          </Alert>
                        </Grid>
                        <Box component="form" sx={{ mt: 3, mb: 2, width: '100%' }}>
                          <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={6}
                            label="Refresh Token"
                            name="Refresh Token"
                            fullWidth
                            required
                            value={refreshToken}
                            sx={{ width: '100%' }}
                          />
                          <Box sx={{ mt: 2 }}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleCopyToClipboard}
                              sx={{ textTransform: 'none' }}
                            >
                              Copy to Clipboard
                            </Button>
                          </Box>

                          <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={3000}
                            onClose={handleSnackbarClose}
                            message="Refresh token copied to clipboard"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Grid container sx={styles.MainGrid}>
                          <Grid item xs={10}>
                            <Typography component="h1" variant="h5">
                              Authorization Failed!
                            </Typography>
                          </Grid>
                          <Grid item xs={2} align="right">
                            <img alt="system" src="/systemJobLightMode.svg" style={{ width: '60px', height: '80px' }} />
                          </Grid>
                          <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert icon={true} severity="error" sx={styles.AlertStyle}>
                              {genericErrorMessage}
                            </Alert>
                          </Stack>
                        </Grid>
                        <p>
                          <center>
                            Please contact <a href="mailto:foremandev@harvestgroup.com">foremandev@harvestgroup.com</a> for
                            assistance.
                          </center>
                        </p>
                      </>
                    )}
                  </div>
                )}
              </Box>
            </Container>
          </Container>
        </div>

        <Container maxWidth="lg" sx={styles.FooterStyle}>
          <Grid container>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} align="center" sm={4}>
              <Typography variant="body1" color="text.primary">
                Our Social Media
              </Typography>
              <Link href="https://www.facebook.com/harvestgroupnwa/" color="inherit">
                <Facebook />
              </Link>
              <Link href="https://www.instagram.com/the_harvest_group/" color="inherit" sx={{ pl: 1, pr: 1 }}>
                <Instagram />
              </Link>
              <Link href="https://www.linkedin.com/company/the-harvest-group/" color="inherit">
                <LinkedIn />
              </Link>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography variant="body2" color="text.secondary" align="center">
              <Link color="inherit" href="https://harvestgroup.com/">
                {'© Harvest Group '}
                {new Date().getFullYear()}
              </Link>
            </Typography>
          </Box>
        </Container>
      </div>
    </>
  );
}
