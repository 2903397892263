export const styles = {
  Container: { border: 'ridge', borderRadius: '5px' },
  UpdatedContainer: { marginTop: '0%', marginBottom: '0%' },
  BoxStyles: {
    marginTop: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  MainGrid: { borderBottom: 'ridge', alignItems: 'center' },
  PasswordInput: { marginBottom: '5%' },
  UpdateButton: { mt: 3, mb: 1, backgroundColor: '#0F273D', marginBottom: 3 },
  AlertStyle: { marginTop: '0%', marginBottom: '10px' },
  NavBarStyle: { backgroundColor: '#0F273D', height: '80px' },
  FooterStyle: {
    backgroundColor: '#EEE3D9',
    width: '100% !important',
    maxWidth: '100% !important',
    height: 'fit-content !important',
    maxHeight: 'fit-content !important',
    marginTop: 'auto',
  },
};
