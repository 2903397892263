export const retailerServiceTypeOptions = {
  default: [{ value: 0, label: 'None' }],
  Amazon: [
    { value: 1, label: 'Amazon Advertising API' },
    { value: 2, label: 'Amazon Seller Central' },
    { value: 3, label: 'Amazon Selling Partner API' },
    { value: 4, label: 'Amazon Vendor Central' },
    { value: 18, label: 'Skai' },
    { value: 19, label: 'Amazon Marketing Cloud' },
    { value: 24, label: 'Criteo API' },
  ],
  Target: [
    { value: 5, label: 'Target Partners Online' },
    { value: 15, label: 'The Trade Desk Target' },
    { value: 18, label: 'Skai' },
    { value: 24, label: 'Criteo API' },
  ],
  'Walmart US': [
    { value: 13, label: 'Walmart US Advertising API' },
    { value: 7, label: 'Walmart US Retail Link BOT' },
    { value: 16, label: 'The Trade Desk Walmart' },
    { value: 18, label: 'Skai' },
    { value: 21, label: 'Walmart US Retail Link MFA' },
    { value: 23, label: 'Walmart Scintilla Charter Portal' },
    { value: 28, label: 'Walmart Scintilla Basic' },
    { value: 24, label: 'Criteo API' },
    { value: 25, label: 'Walmart Scintilla Charter API' },
    { value: 26, label: 'Walmart API' },
    { value: 31, label: 'Walmart Marketplace' },
  ],
  'Walmart Canada': [
    { value: 8, label: 'Walmart Canada' },
    { value: 18, label: 'Skai' },
    { value: 24, label: 'Criteo API' },
  ],
  'Walmart China': [
    { value: 9, label: 'Walmart China' },
    { value: 18, label: 'Skai' },
    { value: 24, label: 'Criteo API' },
  ],
  Kroger: [
    { value: 10, label: 'Kroger' },
    { value: 14, label: 'Kroger IRC Portal' },
    { value: 17, label: 'The Trade Desk Kroger' },
    { value: 18, label: 'Skai' },
    { value: 24, label: 'Criteo API' },
    { value: 27, label: '8451 Stratum' },
  ],
  'Costco Wholesale': [
    { value: 11, label: 'Costco Wholesale' },
    { value: 18, label: 'Skai' },
    { value: 24, label: 'Criteo API' },
  ],
  "Sam's Club": [
    { value: 12, label: "Sam's Club" },
    { value: 18, label: 'Skai' },
    { value: 20, label: "Sam's Club US Advertising API" },
    { value: 24, label: 'Criteo API' },
    { value: 30, label: "Sam's Club Retail Link" },
  ],
  Albertsons: [
    { value: 18, label: 'Skai' },
    { value: 22, label: 'Manual Upload' },
    { value: 24, label: 'Criteo API' },
  ],
  Instacart: [{ value: 29, label: 'Instacart API' }],
  'Other Ads Channels': [
    { value: 18, label: 'Skai' },
    { value: 24, label: 'Criteo API' },
    { value: 34, label: 'The Trade Desk Other Ads Channels' },
  ],
  'Harris Teeter': [
    { value: 35, label: 'Harris Teeter' },
  ],
};
