import React, { useState,useEffect } from 'react';
import { isValidJson, validateQuartzCron } from '../../../Utils/Utilities';
import ReactDatetime from 'react-datetime';
import cronstrue from 'cronstrue';
import * as moment from 'moment';
import {
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Link,
  FormControlLabel,
  Switch,
  TextField,
  IconButton,
  Tooltip,
  FormGroup,
  FormHelperText,
  InputLabel,
  Autocomplete,
} from '@mui/material';
import Select from '@mui/material/Select';
import { styles } from '../Credentials.styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';

export default function JobFormAdditionalFields({
  properties,
  className,
  setDisableButtons,
  formType,
  interfaceBooleanFieldValue,
  interfaceInputBooleanOnChange,
  interfaceInputDateOnChange,
  interfaceInputFieldValue,
  interfaceInputFieldOnChange,
  interfaceInputEnumOnChange,
  interfaceInputMultiSelectOnChange,
  modifiedJob,
  setModifiedJob,
}) {
  const [showAdditionalInfo, setShowAdditionalInfo] = useState('');
  const [isValidDate, setIsValidDate] = useState(false);

  const handleMouseEnter = (propName) => {
    setShowAdditionalInfo(propName);
  };

  const handleMouseLeave = () => {
    setShowAdditionalInfo('');
  };

  useEffect(() => {
    const queryParam = interfaceInputFieldValue('QueryParameters');
  
    if (queryParam.length !== 0) {
      if (!isValidJson(queryParam)) {
        setDisableButtons(true);
        return;
      }
  
      if (JSON.parse(queryParam).hasOwnProperty('marketplaceIds')) {
        setDisableButtons(true);
        return;
      }
    }
  
    setDisableButtons(false);
  }, [interfaceInputFieldValue('QueryParameters')]);

  useEffect(() => {
    const updatedParameters = {};
  
    properties.forEach((property) => {
      if (
        property.selectAllThatApply &&
        property.selectAllThatApply.length &&
        !modifiedJob.parameterValues?.[property.name]
      ) {
        updatedParameters[property.name] = interfaceInputFieldValue(property.name) || property.defaultValue || '';
      }
    });
  
    if (Object.keys(updatedParameters).length > 0) {
      setModifiedJob((prev) => ({
        ...prev,
        parameterValues: {
          ...prev.parameterValues,
          ...updatedParameters,
        },
      }));
    }
  }, [properties]);
  
  

 
  const descriptiveCronExpression = () => {
    const expression = validateQuartzCron(interfaceInputFieldValue('CronExpression'));

    return expression ? (
      <>
        {`This Job will run ${cronstrue
          .toString(interfaceInputFieldValue('CronExpression'))
          .replace('At', 'at')
          .replace('Every', 'every')} (All times UTC)`}
        <br />
      </>
    ) : (
      <span className="warning-message">
        Invalid Cron Expression!
        <br />
      </span>
    );
  };

  const descriptiveQueryParams = () => {
    const queryParam = interfaceInputFieldValue('QueryParameters');
  
    if (queryParam.length !== 0) {
      if (!isValidJson(queryParam)) {

        return (
          <span className="warning-message">
            <div>Please provide a valid JSON value</div>
          </span>
        );
      }
  
      if (JSON.parse(queryParam).hasOwnProperty('marketplaceIds')) {

        return (
          <span className="warning-message">
            marketplaceIds should not be included in the queryParameters for
            <strong> {className}</strong> .
          </span>
        );
      }
    }
  
    return null;
  };
  

  const descriptiveReportOptions = () => {
    const reportOptions = interfaceInputFieldValue('ReportOptions');
    return !reportOptions || isValidJson(reportOptions) ? (
      ''
    ) : (
      <span className="warning-message">
        ReportOptions must be valid JSON (do <strong>not</strong> include the "ReportOptions" key found in the documentation
        examples)
        <br />
      </span>
    );
  };

  const descriptiveStartDate = () => {
    const startDate = interfaceInputFieldValue('StartDate');

    return startDate && isValidDate ? (
      ''
    ) : (
      <span className="warning-message">
        Invalid Start Date!
        <br />
      </span>
    );
  };

  const interfacePropertiesExtraInfo = {
    QueryParameters: {
      specialText: (
        <Typography color="textPrimary" component="span">
          {descriptiveQueryParams()}
        </Typography>
      ),
    },
    CronExpression: {
      specialText: (
        <Typography color="textPrimary" component="span">
          {descriptiveCronExpression()} Need help building a Cron Expression? Try{' '}
          <Link
            href="https://www.freeformatter.com/cron-expression-generator-quartz.html"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
          >
            FreeFormatter
          </Link>
          .
        </Typography>
      ),
    },
    StartDate: {
      specialText: (
        <Typography color="textPrimary" component="span">
          {descriptiveStartDate()}
        </Typography>
      ),
    },
    ShouldObserveDaylightSavingsTime: {
      label: 'ObserveDST',
    },
    ReportOptions: {
      specialText: (
        <Typography color="textPrimary" component="span">
          {descriptiveReportOptions()} Necessary report options for each report type can be found in the{' '}
          <Link
            href="https://developer-docs.amazon.com/sp-api/docs/report-type-values"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
          >
            Selling Partner API Documentation
          </Link>
          .
        </Typography>
      ),
    },
  };
  const isConflicting = (parameter) => {
    const mustNotExistProperty = properties.find((property) => property.name == parameter);

    if (
      formType == 'Job' &&
      mustNotExistProperty != undefined &&
      mustNotExistProperty.defaultValue != undefined &&
      mustNotExistProperty.defaultValue != '' &&
      mustNotExistProperty.defaultValue != 'false'
    ) {
      return true;
    }

    return interfaceInputFieldValue(parameter) != '' && interfaceInputFieldValue(parameter) != 'false';
  };

  const shouldDisableField = (mustNotExist) => {
    if (mustNotExist) {
      const mustNotExistList = mustNotExist.split(',');
      return mustNotExistList.some((parameter) => {
        return isConflicting(parameter);
      });
    }

    return false;
  };

  const getConflicts = (mustNotExist) => {
    let conflicts = [];

    if (mustNotExist) {
      const mustNotExistList = mustNotExist.split(',');
      conflicts = mustNotExistList.filter((parameter) => {
        return isConflicting(parameter);
      });
    }

    return conflicts;
  };

  const isRequiredValue = (requiredField, requiredValue) => {
    const requiredIfProperty = properties.find((property) => property.name == requiredField);

    if (
      formType == 'Job' &&
      requiredIfProperty != undefined &&
      requiredIfProperty.defaultValue != undefined &&
      requiredIfProperty.defaultValue != '' &&
      requiredIfProperty.defaultValue != 'false' &&
      requiredValue == interfaceInputFieldValue(requiredField)
    ) {
      return true;
    }

    return interfaceInputFieldValue(requiredField) != '' && interfaceInputFieldValue(requiredField) != 'false';
  };

  const shouldRequireField = (requiredIf) => {
    if (requiredIf) {
      const requiredField = requiredIf.key;
      const requiredValue = requiredIf.value;
      return isRequiredValue(requiredField, requiredValue);
    }
  };

  const interfaceEnumFieldValue = (property) => {
    const value = interfaceInputFieldValue(property.name);
    return { value: value || property.defaultValue, label: value || property.defaultValue };
  };

  const interfaceMultiSelectFieldValue = (property) => {
    let value = interfaceInputFieldValue(property.name) || property.defaultValue;

    if (!value) {
      return [];
    }

    return value.split(',');
  };

  const interfaceDateTimeInputField = (property) => {
    return (
      <ReactDatetime
        disabled={shouldDisableField(property.mustNotExist)}
        inputProps={{
          className: 'form-control',
          placeholder: 'yyyy-mm-dd',
        }}
        value={interfaceInputFieldValue(property.name)}
        onChange={(value) => {
          const date = moment(value, 'YYYY-MM-DD', true);
          setIsValidDate(date.isValid());

          if (date.isValid()) {
            interfaceInputDateOnChange(property.name, date.toDate());
          }
        }}
        dateFormat="YYYY-MM-DD"
        timeFormat={false}
      />
    );
  };

  const interfaceEnumInputField = (property) => {
    return (
      <Select
        sx={styles.Inputs}
        className="react-select primary"
        classNamePrefix="react-select"
        name="singleSelect"
        disabled={shouldDisableField(property.mustNotExist)}
        value={interfaceEnumFieldValue(property).value || null}
        onChange={(event) => interfaceInputEnumOnChange(property.name, event.target.value)}
      >
        {property.enumValues
          .filter((item) => item != 'NotSet')
          .map((item, index) => (
            <MenuItem key={item} value={index + 1} label={item}>
              {item}
            </MenuItem>
          ))}
      </Select>
    );
  };

  const interfaceMultiSelectInputField = (property) => {
    return (
      <Autocomplete
        sx={styles.Inputs}
        multiple
        filterSelectedOptions
        disableCloseOnSelect
        id="tags-outlined"
        className="basic-multi-select"
        value={interfaceMultiSelectFieldValue(property) || []}
        onChange={(value, event) => {
          interfaceInputMultiSelectOnChange(property.name, event);
        }}
        options={property.selectAllThatApply || ''}
        getOptionLabel={(option) => option || ''}
        renderInput={(params) => <TextField {...params} label="" />}
      />
    );
  };

  const interfaceBooleanInputField = (property) => {
    return (
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={interfaceBooleanFieldValue(property.name) != undefined ? interfaceBooleanFieldValue(property.name) : true}
            disabled={shouldDisableField(property.mustNotExist)}
            onChange={() => interfaceInputBooleanOnChange(property.name)}
          />
        }
        label=""
      />
    );
  };

  const interfaceDefaultInputField = (property) => {
    return (
      <TextField
        sx={styles.Inputs}
        type={property.type.includes('Int') ? 'number' : 'text'}
        autoComplete="off"
        placeholder={shouldDisableField(property.mustNotExist) || property.defaultValue}
        disabled={shouldDisableField(property.mustNotExist)}
        value={interfaceInputFieldValue(property.name) || ''}
        onChange={(event) => interfaceInputFieldOnChange(property.name, event)}
      />
    );
  };

  const interfacePropertyInputField = (property) => {
    if (property.selectAllThatApply && property.selectAllThatApply.length > 0) {
      return interfaceMultiSelectInputField(property);
    }
    if (property.type === 'DateTime') {
      return interfaceDateTimeInputField(property);
    }
    if (property.enumValues && property.enumValues.length > 0) {
      return interfaceEnumInputField(property);
    }
    if (property.type === 'Boolean') {
      return interfaceBooleanInputField(property);
    }
    return interfaceDefaultInputField(property);
  };

  return (
    <>
      {properties.map((property) => (
        <Grid container spacing={1} key={property.name} sx={{ marginTop: '0.5rem' }}>
          <Grid item xs={2}>
            <div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <InputLabel htmlFor="format-input" sx={{ display: 'inline' }}>
                {(interfacePropertiesExtraInfo[property.name] && interfacePropertiesExtraInfo[property.name].label) ||
                  property.name}
                <Tooltip title={`${property.required ? 'REQUIRED: ' : ''}${property.description}`} placement="top">
                  <IconButton
                    key={`info${property.name}`}
                    id={`info${property.name}id`}
                    sx={{ ml: 1 }}
                    onMouseEnter={() => handleMouseEnter(property.name)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {!shouldDisableField(property.mustNotExist) ? (
                      !shouldRequireField(property.requiredIf) ? (
                        <InfoOutlinedIcon sx={{ color: property.required ? 'error.main' : 'inherit' }} />
                      ) : (
                        <WarningAmberOutlinedIcon sx={{ color: 'error.main' }} />
                      )
                    ) : (
                      <GppBadOutlinedIcon sx={{ color: 'error.main' }} />
                    )}
                  </IconButton>
                </Tooltip>
                {showAdditionalInfo === property.name && (
                  <div
                    sx={{
                      maxWidth: 240,
                      fontSize: '0.875rem',
                      padding: '6px 12px',
                      borderRadius: 4,
                      backgroundColor: property.required ? '#f44336' : '#f5f5f5',
                      color: property.required ? '#fff' : 'inherit',
                    }}
                  ></div>
                )}{' '}
              </InputLabel>
            </div>
          </Grid>
          <Grid item xs={10}>
            <FormGroup>
              <FormControl>{interfacePropertyInputField(property)}</FormControl>
              {interfacePropertiesExtraInfo[property.name] && (
                <FormHelperText>{interfacePropertiesExtraInfo[property.name].specialText}</FormHelperText>
              )}
            </FormGroup>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
