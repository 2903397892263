import React, { useState, useContext, useEffect } from 'react';
import CustomModal from '../../Components/Modals/CustomModal';
import { CredentialForm } from './credentialForm';
import {
  createCredential,
  updateCredential,
  getTheTradeDeskAuthenticationInfo,
  getJobGroupsWithTemplates,
} from '../../../Utilities/ForemanApiClient';
import { showSnackbar } from '../../Utils/showSnackBar';
import { CredentialsContext } from '../../Context/CredentialsWithBadPasswordContext';

export default function CredentialsFormModal({
  credential,
  handleCloseModal,
  isJobListingMode,
  isEditMode,
  isCreateMode,
  open,
  mode,
  retailers,
  childClient,
  setCredentialsByChildClientId,
  selectedChildClient,
  amazonSPAPIMarketplaceOptions,
}) {
  const [modifiedCredential, setModifiedCredential] = useState(credential);
  const [selectedRetailerServiceType, setSelectedRetailerServiceType] = useState({ value: '' });
  const [selectedAmazonAccountType, setSelectedAmazonAccountType] = useState({});
  const [selectedWalmartAdsLocation, setSelectedWalmartAdsLocation] = useState({});
  const [disableButtons, setDisableButtons] = useState(false);
  const [jobGroups, setJobGroups] = useState([]);

  const { getCredentialsWithBadPassword } = useContext(CredentialsContext);

  useEffect(() => {
    initializeJobGroups();
  }, []);

  const initializeJobGroups = async () => {
    if (selectedChildClient != undefined) {
      const groups = await getJobGroupsWithTemplates();
      groups.unshift({ id: null, name: 'UNASSIGNED' });

      const filteredJobGroups = groups.filter((group) => group.retailerType == selectedChildClient.value.retailer.retailerType);

      setJobGroups(filteredJobGroups);

      return filteredJobGroups;
    }
  };

  const submitCredentialForm = async () => {
    let success = false;

    if (mode == 'edit') {
      success = await updateCredential(modifiedCredential);
    } else {
      success = await createCredential(modifiedCredential);
    }

    handleCloseModal();

    if (!success) {
      showSnackbar(
        'error',
        `Credential ${credential.id} ${mode} unsuccessful. Please try again later; if the issue persists, contact dev: foremandev@harvestgroup.com`
      );
    } else {
      showSnackbar('success', `Credential ${mode} successfully!`);
      getCredentialsWithBadPassword();
    }

    await setCredentialsByChildClientId(selectedChildClient.value.id);
  };

  const submitForm = async () => {
    setDisableButtons(true);
    if (selectedRetailerServiceType.value === 3 && !modifiedCredential.username) {
      modifiedCredential.username = `Selling Partner API account (${selectedAmazonAccountType.value})`;
      modifiedCredential.password = '***';
    }

    if (selectedRetailerServiceType.value === 13 && !modifiedCredential.username) {
      modifiedCredential.username = 'Walmart US Advertising API account';
      modifiedCredential.password = '***';
    }

    if (selectedRetailerServiceType.value === 20 && !modifiedCredential.username) {
      modifiedCredential.username = "Sam's Club US Advertising API account";
      modifiedCredential.password = '***';
    }

    if (selectedRetailerServiceType.value === 18) {
      modifiedCredential.username = `Skai account`;
      modifiedCredential.password = '***';
    }

    if (selectedRetailerServiceType.value === 22) {
      modifiedCredential.username = `Manual Upload account`;
      modifiedCredential.password = '***';
    }

    if (selectedRetailerServiceType.value === 19) {
      modifiedCredential.username = `Amazon Marketing Cloud account`;
      modifiedCredential.password = '***';
    }

    if (selectedRetailerServiceType.value === 25 && !modifiedCredential.username) {
      modifiedCredential.username = `${childClient.value.name}_luminate_api`;
      modifiedCredential.password = '***';
    }

    if (
      selectedRetailerServiceType.value === 16 ||
      selectedRetailerServiceType.value === 15 ||
      selectedRetailerServiceType.value === 17 ||
      selectedRetailerServiceType.value === 34
    ) {
      const theTradeDeskAuthenticationInfo = await getTheTradeDeskAuthenticationInfo(selectedRetailerServiceType.value);

      modifiedCredential.username = theTradeDeskAuthenticationInfo.login;
      modifiedCredential.password = theTradeDeskAuthenticationInfo.password;
    }
    await submitCredentialForm(modifiedCredential);
    setDisableButtons(false);
  };

  return (
    <>
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        title={isJobListingMode ? credential.username + ' jobs' : mode + ' credential'}
        isEditMode={isEditMode}
        isCreateMode={isCreateMode}
        confirmEnabled={!isJobListingMode}
        disabled={disableButtons || !selectedRetailerServiceType.value}
        handleConfirm={submitForm}
      >
        <CredentialForm
          credential={credential}
          mode={mode}
          jobGroups={jobGroups}
          retailers={retailers}
          childClient={childClient}
          modifiedCredential={modifiedCredential}
          setModifiedCredential={setModifiedCredential}
          amazonSPAPIMarketplaceOptions={amazonSPAPIMarketplaceOptions}
          selectedRetailerServiceType={selectedRetailerServiceType}
          setSelectedRetailerServiceType={setSelectedRetailerServiceType}
          selectedAmazonAccountType={selectedAmazonAccountType}
          setSelectedAmazonAccountType={setSelectedAmazonAccountType}
          selectedWalmartAdsLocation={selectedWalmartAdsLocation}
          setSelectedWalmartAdsLocation={setSelectedWalmartAdsLocation}
          disableButtons={disableButtons}
          setDisableButtons={setDisableButtons}
        />
      </CustomModal>
    </>
  );
}
