let backendHost;
let frontendHost;
let harvesterHost;
let lanternBackendHost;
let environment;
let amazonSellingPartnerApiRedirectUrl;
let amazonSellingPartnerApiAuthUrl;
let amazonAdvertisingApiRedirectUrl;
let amazonAdvertisingApiAuthUrl;
let walmartMarketPlaceApiAuthUrl;
let walmartMarketPlaceApiRedirectUrl;

const demoHostnames = ['https://foreman-frontend-cdn-demo.azureedge.net'];
const testHostnames = [
  'foreman-test.harvestgroup.com',
  'foreman-frontend-cdn-test.azureedge.net',
  'hgretailreportertest.z19.web.core.windows.net',
  'https://Foreman-Frontend-Test-cjajezeuckdeg3ep.z01.azurefd.net',
];
const prodHostnames = [
  'foreman.harvestgroup.com',
  'foreman-frontend-cdn-prod.azureedge.net',
  'hgretailreporterprod.z19.web.core.windows.net',
  'https://Foreman-Frontend-Prod-hpbmeubbdhg3haeb.z01.azurefd.net',
];

const hostname = window && window.location && window.location.hostname;

export const isProductionEnvironment = () => {
  return prodHostnames.includes(hostname);
};

export const isTestEnvironment = () => {
  return testHostnames.includes(hostname);
};

export const isDemoEnvironment = () => {
  return demoHostnames.includes(hostname);
};

export const isLocalEnvironment = () => {
  return !isProductionEnvironment() && !isTestEnvironment() && !isDemoEnvironment();
};

if (isDemoEnvironment()) {
  harvesterHost = 'https://retailreporter-harvesters-test.azurewebsites.net/api';
  backendHost = 'https://foreman-api-test.harvestgroup.com/api';
  frontendHost = 'https://foreman-frontend-cdn-demo.azureedge.net';
  lanternBackendHost = 'https://lantern-api-test.harvestgroup.com/api';
  environment = 'Demo';
  amazonSellingPartnerApiRedirectUrl = 'https://foreman-test.harvestgroup.com/sellingPartnerApiRefreshToken';
  amazonSellingPartnerApiAuthUrl = 'https://foreman-test.harvestgroup.com/SellingPartnerApiAuth?credentialId=';
  amazonAdvertisingApiRedirectUrl = 'https://foreman-test.harvestgroup.com/amazonAdvertisingApiRefreshToken';
  amazonAdvertisingApiAuthUrl = 'https://foreman-test.harvestgroup.com/amazonAdvertisingApiAuth?credentialId=';
  walmartMarketPlaceApiRedirectUrl = 'https://foreman-test.harvestgroup.com/WalmartMarketplaceApiCallback';
  walmartMarketPlaceApiAuthUrl = 'https://foreman-test.harvestgroup.com/WalmartMarketplaceApiAuth?credentialId=';
} else if (isTestEnvironment()) {
  harvesterHost = 'https://retailreporter-harvesters-test.azurewebsites.net/api';
  backendHost = 'https://foreman-api-test.harvestgroup.com/api';
  frontendHost = 'https://foreman-test.harvestgroup.com';
  lanternBackendHost = 'https://lantern-api-test.harvestgroup.com/api';
  environment = 'Test';
  amazonSellingPartnerApiRedirectUrl = 'https://foreman-test.harvestgroup.com/sellingPartnerApiRefreshToken&version=beta';
  amazonSellingPartnerApiAuthUrl = 'https://foreman-test.harvestgroup.com/SellingPartnerApiAuth?credentialId=';
  amazonAdvertisingApiRedirectUrl = 'https://foreman-test.harvestgroup.com/amazonAdvertisingApiRefreshToken';
  amazonAdvertisingApiAuthUrl = 'https://foreman-test.harvestgroup.com/amazonAdvertisingApiAuth?credentialId=';
  walmartMarketPlaceApiRedirectUrl = 'https://foreman-test.harvestgroup.com/WalmartMarketplaceApiCallback';
  walmartMarketPlaceApiAuthUrl = 'https://foreman-test.harvestgroup.com/WalmartMarketplaceApiAuth?credentialId=';
} else if (isProductionEnvironment()) {
  harvesterHost = 'https://retailreporter-harvesters-prod.azurewebsites.net/api';
  backendHost = 'https://foreman-api.harvestgroup.com/api';
  frontendHost = 'https://foreman.harvestgroup.com';
  lanternBackendHost = 'https://lantern-api.harvestgroup.com/api';
  environment = 'Production';
  amazonSellingPartnerApiRedirectUrl = 'https://foreman.harvestgroup.com/sellingPartnerApiRefreshToken';
  amazonSellingPartnerApiAuthUrl = 'https://foreman.harvestgroup.com/SellingPartnerApiAuth?credentialId=';
  amazonAdvertisingApiRedirectUrl = 'https://foreman.harvestgroup.com/amazonAdvertisingApiRefreshToken';
  amazonAdvertisingApiAuthUrl = 'https://foreman.harvestgroup.com/amazonAdvertisingApiAuth?credentialId=';
  walmartMarketPlaceApiRedirectUrl = 'https://foreman.harvestgroup.com/WalmartMarketplaceApiCallback';
  walmartMarketPlaceApiAuthUrl = 'https://foreman.harvestgroup.com/WalmartMarketplaceApiAuth?credentialId=';
} else {
  harvesterHost = 'http://localhost:7071/api';
  backendHost = 'http://localhost/api';
  frontendHost = 'http://localhost:3000';
  lanternBackendHost = 'https://lantern-api-test.harvestgroup.com/api';
  environment = 'Local';
  amazonSellingPartnerApiRedirectUrl = 'http://localhost:3000/sellingPartnerRefreshToken';
  amazonSellingPartnerApiAuthUrl = 'http://localhost:3000/SellingPartnerApiAuth?credentialId=';
  amazonAdvertisingApiRedirectUrl = 'http://localhost:3000/amazonAdvertisingApiRefreshToken';
  amazonAdvertisingApiAuthUrl = 'http://localhost:3000/amazonAdvertisingApiAuth?credentialId=';
  walmartMarketPlaceApiRedirectUrl = 'https://foreman-test.harvestgroup.com/WalmartMarketplaceApiCallback';
  walmartMarketPlaceApiAuthUrl = 'http://localhost:3000/WalmartMarketplaceApiAuth?credentialId=';
}

export const HARVESTER_ROOT = harvesterHost;
export const API_ROOT = backendHost;
export const FRONTEND_ROOT = frontendHost;
export const LANTERN_API_ROOT = lanternBackendHost;
export const ENVIRONMENT = environment;
export const AMAZON_SELLING_PARTNER_API_REDIRECT_URI = amazonSellingPartnerApiRedirectUrl;
export const AMAZON_SELLING_PARTNER_API_AUTH_URL = amazonSellingPartnerApiAuthUrl;
export const AMAZON_ADVERTISING_API_REDIRECT_URI = amazonAdvertisingApiRedirectUrl;
export const AMAZON_ADVERTISING_API_AUTH_URL = amazonAdvertisingApiAuthUrl;
export const WALMART_MARKETPLACE_API_AUTH_URL = walmartMarketPlaceApiAuthUrl;
export const WALMART_MARKETPLACE_API_REDIRECT_URI = walmartMarketPlaceApiRedirectUrl;
