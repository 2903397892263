/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { CardImg, CardImgOverlay, Card, CardTitle } from 'reactstrap';
import HarvestLogo from '../resources/harvest_logomark_white.svg';
import { styles } from './WalmartMarketplaceApiAuthStyles';
import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  Typography,
  Alert,
  Autocomplete,
  TextField,
} from '@mui/material';
import { Facebook } from '@mui/icons-material';
import { Instagram } from '@mui/icons-material';
import { LinkedIn } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import { WALMART_MARKETPLACE_API_REDIRECT_URI } from '../api-config';

export default function WalmartMarketplaceApiAuth() {
  const [selectedMarketplace, setSelectedMarketplace] = useState({ value: '', label: 'US' });
  const [cookies, setCookie] = useCookies(['foremanState']);
  const [foremanState, setForemanState] = useState(null);
  const [nonce, setNonce] = useState(null);
  const [walmartDataSource, setWalmartDataSource] = useState(null);

  const queryParameters = new URLSearchParams(window.location.search);
  const credentialId = queryParameters.get('credentialId');
  const clientType = queryParameters.get('clientType');
  const marketplaceSource = 'Walmart Marketplace (3P)';
  const supplierOneSource = 'Walmart Supplier One (1P)';

  useEffect(() => {
    clientType === 'supplier' ? setWalmartDataSource(supplierOneSource) : setWalmartDataSource(marketplaceSource);

    const firstAvailableRetailerServiceType = mapSelectedRegionToMarketplaceOptions()[0];
    selectedMarketplaceOnChange(firstAvailableRetailerServiceType.value, firstAvailableRetailerServiceType.label);
  }, []);

  const mapSelectedRegionToMarketplaceOptions = () => {
    return [
      { value: '', label: 'US' },
      { value: 'ca', label: 'Canada' },
      { value: 'mx', label: 'Mexico' },
    ];
  };

  const selectedMarketplaceOnChange = (value, label) => {
    setSelectedMarketplace({ value: value, label: label });
  };

  const generateRandomString = (length) => {
    return Array.from({ length }, () =>
      String.fromCharCode(Math.random() < 0.5 ? Math.floor(Math.random() * 10) + 48 : Math.floor(Math.random() * 26) + 65)
    ).join('');
  };

  const saveCredentialIdToCookies = () => {
    setCookie('credentialId', credentialId, {
      path: '/',
      secure: true,
    });
  };

  const saveClientTypeToCookies = () => {
    setCookie('clientType', clientType, {
      path: '/',
      secure: true,
    });
  };

  const saveStateToCookies = (state) => {
    setCookie('foremanState', state, {
      path: '/',
      secure: true,
    });
  };

  const sendToWalmart = async () => {
    //this will be removed once we validate our process
    const clientId = encodeURI(clientType ? '9d8557d8-5322-4314-b2e6-ab1c48fefdcf' : 'ebd9dad2-390d-463f-8fc7-5ba13639e240');
    const marketplaceSuffix = selectedMarketplace.label != 'US' ? `-${selectedMarketplace.value}` : '';
    const clientTypePrefix = clientType == 'supplier' ? `supplier` : 'seller';
    const callbackURL = encodeURI(WALMART_MARKETPLACE_API_REDIRECT_URI);
    const nonce = generateRandomString(10);
    const state = generateRandomString(10);

    setNonce(nonce);
    setForemanState(state);

    saveCredentialIdToCookies();
    saveStateToCookies(state);

    window.location.href = `https://login.account.wal-mart.com/authorize?responseType=code&clientId=${clientId}&redirectUri=${callbackURL}&nonce=${nonce}&state=${state}&clientType=${clientTypePrefix}${marketplaceSuffix}`;
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar position="static" sx={styles.NavBarStyle}>
          <img
            alt="system"
            src="/whitelogo.svg"
            style={{ width: '100px', height: '50px', marginLeft: '20px', marginTop: '12px' }}
          />
        </AppBar>

        <div className="content compact-centered">
          <Container component="main" maxWidth="md" sx={styles.UpdatedContainer}>
            <Container component="main" maxWidth="md" sx={styles.Container}>
              <CssBaseline />

              <Box sx={styles.BoxStyles}>
                <Grid container sx={styles.MainGrid}>
                  <Grid item xs={10}>
                    <Typography component="h1" variant="h5">
                      Authorize Harvest Group for
                    </Typography>
                    <Typography component="h1" variant="h5">
                      {walmartDataSource}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} align="right">
                    <img alt="system" src="/systemJobLightMode.svg" style={{ width: '60px', height: '80px' }} />
                  </Grid>
                  <Alert icon={false} severity="info" sx={styles.AlertStyle}>
                    <strong>Note:</strong> By clicking on the authorize button, you are granting Harvest Group LLC access to your{' '}
                    {walmartDataSource} data as a service provider.
                  </Alert>
                </Grid>

                <Box component="form" sx={{ mt: 3 }}>
                  <Autocomplete
                    id="combo-box-demo"
                    style={{ width: '20vw' }}
                    getOptionLabel={(option) => option.label || ''}
                    value={selectedMarketplace}
                    onChange={(event, newValue) => {
                      if (newValue) selectedMarketplaceOnChange(newValue.value, newValue.label);
                    }}
                    options={mapSelectedRegionToMarketplaceOptions()}
                    isOptionEqualToValue={(option, value) => option.value === value?.value}
                    renderInput={(params) => <TextField {...params} label="Select Marketplace" variant="outlined" />}
                  />

                  <Button onClick={sendToWalmart} fullWidth variant="contained" sx={styles.UpdateButton}>
                    Authorize
                  </Button>
                </Box>
              </Box>
            </Container>
          </Container>
        </div>

        <Container maxWidth="lg" sx={styles.FooterStyle}>
          <Grid container>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} align="center" sm={4}>
              <Typography variant="body1" color="text.primary">
                Our Social Media
              </Typography>
              <Link href="https://www.facebook.com/harvestgroupnwa/" color="inherit">
                <Facebook />
              </Link>
              <Link href="https://www.instagram.com/the_harvest_group/" color="inherit" sx={{ pl: 1, pr: 1 }}>
                <Instagram />
              </Link>
              <Link href="https://www.linkedin.com/company/the-harvest-group/" color="inherit">
                <LinkedIn />
              </Link>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography variant="body2" color="text.secondary" align="center">
              <Link color="inherit" href="https://harvestgroup.com/">
                {'© Harvest Group '}
                {new Date().getFullYear()}
              </Link>
            </Typography>
          </Box>
        </Container>
      </div>
    </>
  );
}
